<script setup lang="ts">
import { defineProps, withDefaults, computed } from "vue";
import { type DataObjectProgress } from "o365.pwa.modules.client.steps.DataObjectProgress.ts";
import { SyncStatus } from "o365.pwa.modules.client.steps.StepSyncProgress.ts";

interface IProps {
	syncStepProgress: DataObjectProgress;
	currentStep: boolean;
}

const props = withDefaults(defineProps<IProps>(), {
	currentStep: false,
});

const percentage = (curr, max) => {
	return (100 * curr) / max;
};

const syncProgress = <DataObjectProgress>computed(() => {
	return props.syncStepProgress.onlineSyncProgress;
});

const hasStarted = computed(() => {
	return (
		syncProgress.value.uploadRecordsStarted
		|| syncProgress.value.uploadRecordsCompleted
		|| syncProgress.value.uploadFilesStarted
		|| syncProgress.value.uploadFilesCompleted
	);
});

const hasCompleted = computed(() => {
	return (
		syncProgress.value.uploadRecordsCompleted
		&& syncProgress.value.uploadFilesCompleted
	);
});

const hasErrors = computed(() => {
	return (
		syncProgress.value.uploadRecordsCompletedWithError
		|| syncProgress.value.uploadFilesCompletedWithError
	);
});

const combinedErrors = computed(() => {
	return combineMessages(props.syncStepProgress.uiFriendlyMessages);
});

// Define the interface for the output
interface CombinedMessage {
	title: string;
	body: string;
	count: number;
}

function combineMessages(messages: Array<{ title: string; body: string; }>): CombinedMessage[] {
	const messageCounts: Record<string, number> = {};

	messages.forEach(msg => {
		const key = `${msg.title}_${msg.body}`;  // Create a unique key based on title and body
		messageCounts[key] = (messageCounts[key] || 0) + 1;
	});

	const combinedMessages: CombinedMessage[] = [];
	for (const [key, count] of Object.entries(messageCounts)) {
		const [title, body] = key.split('_');  // Split the key back to title and body
		combinedMessages.push({ title, body, count });
	}

	return combinedMessages;
}
</script>

<script lang="ts">
export default {
	name: "DataObjectProgressOnline",
};
</script>

<template>
	<div class="card shadow-sm mb-3 w-100">
		<div class="card-header d-flex justify-content-between">
			<div class="d-flex flex-column">
				<!-- Definition -->
				<div class="fw-bold">
					<span>{{ props.syncStepProgress.title }}</span>
				</div>

				<!-- TITLE -->
				<div class="small-subtitle">
					Data Object
				</div>
			</div>

			<!-- STATUS -->
			<div class="d-flex justify-content-center">
				<template v-if="props.syncStepProgress.syncStatus === SyncStatus.PendingStart">
					<span class="text-black d-flex align-items-center gap-1" v-if="!hasStarted">
						<i class="bi bi-pause-circle-fill text-black me-1"></i>
						Waiting to start
					</span>
				</template>

				<template v-if="props.syncStepProgress.syncStatus === SyncStatus.SyncingComplete">
					<span class="text-success d-flex align-items-center gap-1" v-if="hasCompleted">
						<i class="bi bi-check-circle-fill text-success me-1"></i>
						Completed
					</span>
				</template>

				<template v-if="props.syncStepProgress.syncStatus === SyncStatus.SyncingCompleteWithErrors">
					<span class="text-danger d-flex align-items-center gap-1" v-if="hasErrors">
						<i class="bi bi-exclamation-circle-fill text-danger me-1"></i>
						Error completing
					</span>
				</template>

				<template v-if="props.syncStepProgress.syncStatus === SyncStatus.SyncingCanceled">
					<span class="text-danger d-flex align-items-center gap-1">
						<i class="bi bi-x-octagon-fill text-danger me-1"></i>
						Cancelled
					</span>
				</template>
			</div>
		</div>

		<div class="card-body">
			<div>
				<template
					v-if="syncProgress.retrieveRowCountStarted && !syncProgress.retrieveRowCountCompleted && !syncProgress.retrieveRowCountCompletedWithError">
					<div class="d-flex align-items-center justify-content-between gap-1">
						<div>Started retrieving row count...</div>
						
						<div class="spinner-border spinner-border-sm" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
					</div>
				</template>

				<template
					v-if="syncProgress.retrieveRowCountCompletedWithError && !syncProgress.retrieveRowCountCompleted">
					<div class="d-flex align-items-center justify-content-between gap-1 text-danger-subtle">
						<div>Errors retrieving row count.</div>
						
						<div>
							<i class="bi bi-exclamation-circle-fill text-danger"></i>
						</div>
					</div>
				</template>

				<div v-if="syncProgress.retrieveRowCountCompleted"
					class="d-flex align-items-center justify-content-between gap-1">
					<div>Completed retrieving row count.</div>

					<div>
						<i class="bi bi-check-circle-fill text-success"></i>
					</div>
				</div>

				<!-- APP RECORDS -->
				<template v-if="hasStarted && !hasErrors && !hasCompleted">
					<div class="d-flex align-items-center justify-content-between gap-1">
						<div>Syncing app records...</div>

						<div class="spinner-border spinner-border-sm" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
					</div>
				</template>

				<template v-if="hasErrors">
					<div class="d-flex align-items-center justify-content-between gap-1 text-danger-subtle">
						<div>Errors syncing app records.</div>
						
						<div>
							<i class="bi bi-exclamation-circle-fill text-danger"></i>
						</div>
					</div>
				</template>

				<template v-if="hasCompleted">
					<div class="d-flex align-items-center justify-content-between gap-1 text-success-subtle">
						<div>Completed syncing app records.</div>

						<div>
							<i class="bi bi-check-circle-fill text-success"></i>
						</div>
					</div>
				</template>

				<!-- CLEAN UP -->
				<template v-if="props.syncStepProgress.syncStatus === SyncStatus.SyncingCompletedStartedCleaning">
					<div class="d-flex align-items-center justify-content-between gap-1">
						<div>Sync completed. Running record cleanup...</div>

						<div class="spinner-border spinner-border-sm" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
					</div>
				</template>

				<template v-if="props.syncStepProgress.syncStatus === SyncStatus.SyncingComplete">
					<div class="d-flex align-items-center justify-content-between gap-1 text-success-subtle">
						<div>Completed record cleanup.</div>

						<div>
							<i class="bi bi-check-circle-fill text-success"></i>
						</div>
					</div>
				</template>

				<template v-if="hasStarted && syncProgress.recordsToUpload > 0">
					<div class="d-flex w-100 justify-content-between">
						<div class="d-flex gap-1 align-items-baseline">Synced <div style="font-size: 12px;"
								class="text-info">Records</div>
						</div>

						<div class="d-flex gap-1">
							<span>Records: </span>
							<span>{{ syncProgress.recordsUploaded }}</span>
							<span> / </span>
							<span>{{ syncProgress.recordsToUpload }}</span>
						</div>
					</div>

					<!-- Records uploading -->
					<div class="progress-stacked my-1" :style="{ height: hasCompleted ? '5px' : '15px' }">
						<!-- Stored -->
						<div class="progress" role="progressbar"
							:aria-valuenow="percentage(syncProgress.recordsUploaded, syncProgress.recordsToUpload)"
							aria-valuemin="0" aria-valuemax="100"
							:style="{ width: percentage(syncProgress.recordsUploaded, syncProgress.recordsToUpload) + '%' }">
							<div v-if="syncProgress.uploadRecordsCompleted" class="progress-bar bg-purple"></div>
							<div v-else class="progress-bar progress-bar-striped progress-bar-animated bg-purple"></div>
						</div>

						<!-- Failed -->
						<div class="progress" role="progressbar"
							:aria-valuenow="percentage(syncProgress.recordsUploadedWithError, syncProgress.recordsToUpload)"
							aria-valuemin="0" aria-valuemax="100"
							:style="{ width: percentage(syncProgress.recordsUploadedWithError, syncProgress.recordsToUpload) + '%' }">
							<div v-if="syncProgress.uploadRecordsCompleted" class="progress-bar bg-danger"></div>
							<div v-else class="progress-bar progress-bar-striped progress-bar-animated bg-danger"></div>
						</div>
					</div>
				</template>

				<template
					v-if="(syncProgress.uploadFilesStarted || syncProgress.uploadFilesCompleted) && syncProgress.filesToUpload > 0">
					<div class="d-flex w-100 justify-content-between">
						<div class="d-flex gap-1 align-items-baseline">
							Synced
							
							<div style="font-size: 12px;" class="text-info">
								Files
							</div>
						</div>

						<div class="d-flex gap-1">
							<span>Files: </span>
							<span>{{ syncProgress.filesUploaded }}</span>
							<span> / </span>
							<span>{{ syncProgress.filesToUpload }}</span>
						</div>
					</div>

					<div class="progress-stacked my-1" :style="{ height: syncProgress.uploadFilesCompleted ? '5px' : '15px' }">
						<!-- Stored -->
						<div class="progress" role="progressbar"
							:aria-valuenow="percentage(syncProgress.filesUploaded, syncProgress.filesToUpload)"
							aria-valuemin="0" aria-valuemax="100"
							:style="{ width: percentage(syncProgress.filesUploaded, syncProgress.filesToUpload) + '%' }">
							<div v-if="syncProgress.uploadFilesCompleted" class="progress-bar bg-purple"></div>
							<div v-else class="progress-bar progress-bar-striped progress-bar-animated bg-purple"></div>
						</div>

						<!-- Failed -->
						<div class="progress" role="progressbar"
							:aria-valuenow="percentage(syncProgress.filesUploadedWithError, syncProgress.filesToUpload)"
							aria-valuemin="0" aria-valuemax="100"
							:style="{ width: percentage(syncProgress.filesUploadedWithError, syncProgress.filesToUpload) + '%' }">
							<div v-if="syncProgress.uploadFilesCompleted" class="progress-bar bg-danger"></div>
							<div v-else class="progress-bar progress-bar-striped progress-bar-animated bg-danger"></div>
						</div>
					</div>
				</template>

				<div v-if="combinedErrors.length > 0">
					<a class="text-danger fs-6 text-decoration-none" data-bs-toggle="collapse"
						:href="'#' + props.syncStepProgress.title.replaceAll(' ', '')" role="button" aria-expanded="false"
						aria-controls="collapseExample">
						Show errors ({{ combinedErrors.length }})
					</a>
				</div>

				<template v-if="combinedErrors.length > 0">
					<div class="collapse" :id="props.syncStepProgress.title.replaceAll(' ', '')">
						<div class="card card-body d-flex bg-danger-subtle w-100">
							<ul class="list-group w-100">
								<li v-for="uiFriendlyMessage in combinedErrors"
									class="list-group-item list-group-item-danger d-flex flex-column">
									<span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
										{{ uiFriendlyMessage.count }}
										
										<span class="visually-hidden">
											Error count
										</span>
									</span>

									<div class="fw-bold fs-6 text-nowrap">
										{{ uiFriendlyMessage.title }}
									</div>

									<div class="fs-6">
										{{ uiFriendlyMessage.body }}
									</div>
								</li>
							</ul>
						</div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<style scoped>
.bg-purple {
	background-color: #9a80e9;
}

.small-subtitle {
	font-size: 12px;
}
</style>
